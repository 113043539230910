
<!--NAVBAR-->
	<header id="home" class="header">
		<nav>
			<div class="logo"> 
				<!-- <img src="../assets/logo.png" width="25" alt="" style="margin-right: 11px; "> -->
				<span  style=" font-size: larger; padding-top:0px; margin-top:-5px;">  <strong>BioPaysage</strong> </span>	
			</div>
			<ul class="nav-links">
				<a href="#home" ><li class="btn btn-2">Home</li></a>
				<a id="FirstClick" href="#introduction" ><li class="btn btn-2">Introduction</li></a>
				<a href="#services" ><li class="btn btn-2">Services</li></a>
				<a href="#products" ><li class="btn btn-2">Produits</li></a>
				<a href="#footer" ><li class="btn btn-2">Contact</li></a>
			</ul>
			<div class="mobile-menu">
				<div class="line"></div>
				<div class="line"></div>
				<div class="line"></div>
				</div>
		</nav>
	</header>
<!--BANNER-->
	<div class="bv-video">
		<div>
			<video #videoRef > 
				<source src="../assets/vid.webm" type="video/mp4">
				<!-- <source src="../assets/vid.webm" type="video/webm">  -->
				<!-- <source src="../assets/vid.ogg" type="video/ogg"> -->
			</video>
		</div>
	</div>

<!--MAIN-->
<span id="introduction" ></span>
	<main >
		<div  class="Introduction ">
			<div class="information ">
				<div class="text">
					<span style=" font-size:large; font-weight: bold;">BioPaysage</span> 
					est une agence de conception en aménagement paysager qui s’occupe de Designer et 
					de mettre en oeuvre vos jardins tout en faisant attention à leur impact écologique ainsi qu’à 
					leur durabilité. <br>
					Nous sommes une équipe de paysagistes, architectes, permaculteurs et jardiniers,
						passionnés par la nature et ayant le souci du détail. <br>
					Au fil des années nous avons developpé une sensibilité et une connexion à la nature qui nous
						permettent aujourd’hui de travailler dans l’harmonie entre l’estetique et l’écologique.
				</div>
			</div>
			<div >
				<img class="pict" src="../assets/logo-tr.png" alt="">
			</div>
		</div>

		<!-- Slide Show-->
		<section class="slide-container">
			<div >
				<div id="slides">
					<img src="../assets/Slides/1-min.jpg">
					<img src="../assets/Slides/2-min.jpg">
					<!-- <img src="../assets/Slides/3-min.jpg"> -->
					<img src="../assets/Slides/4-min.jpg">
					<img src="../assets/Slides/5-min.jpg">
					<!-- <img src="../assets/Slides/6-min.jpg"> -->
					<img src="../assets/Slides/7-min.jpeg">
					<img src="../assets/Slides/8-min.jpg">
					<img src="../assets/Slides/9-min.jpg">
					<img src="../assets/Slides/10-min.jpg">
					<img src="../assets/Slides/11-min.jpg">
					<img src="../assets/Slides/12-min.jpg">
					<img src="../assets/Slides/13-min.jpg">
					<img src="../assets/Slides/14-min.jpg">

					<a href="#" class="slidesjs-previous slidesjs-navigation"><i class="fas fa-chevron-left"></i></a>
					<a href="#" class="slidesjs-next slidesjs-navigation"><i class="fas fa-chevron-right"></i></a>
				</div>
			</div>
		</section>

		<!-- SERVICES -->
		<h1 class="main-titles">SERVICES</h1>
		<section id="services" class="services">
				<!-- One -->

					<div class="blog-card">
						<div class="meta">
							<div class="photo" style="background-image: url(../assets/Services/conception-min.jpg)"></div>  
						</div>
						<div class="description">
							<h1>Conception d’espaces verts</h1>
							<p> BioPaysage conçoit votre jardin (extérieur ou intérieur) avec beaucoup de finesse et d’ingéniosité.
								Après vous avoir rencontré et déterminé vos besoin, nous vous fournirons une proposition contenant 
								un plan d’aménagement, des perspectives 3D, un plan d’arrosage et un plan d’éclairage. <br> 
								Nous proposerons aussi les différents matériaux, voieries et aussi les meubles de jardin.
								Enfin nous vous fournirons aussi une estimation détaillée des coûts de mise en œuvre.
								Un dossier complet pour pouvoir réaliser votre jardin!									
							</p>
						</div>
					</div>

				<!-- Two -->

					<div class="blog-card alt">
						<div class="meta">
							<div class="photo" style="background-image: url(../assets/Services/realisation-min.jpg)"></div>  
						</div>
						<div class="description">
							<h1>Réalisation</h1>
							<p> 
								<br> Notre équipe de jardiniers et techniciens spécialisés s’occupe de la réalisation du jardin en
									assurant un bon rapport qualité/prix et surtout des délais d’exécution très courts. <br>
									Nous délivrons aussi des garanties allant de 3 à 12 mois selon la nature du travail.
							</p>
						</div>
					</div>

				<!-- Three -->
				
					<div class="blog-card">
						<div class="meta">
							<div class="photo" style="background-image: url(../assets/Services/entretenir-min.jpg)"></div>  
						</div>
						<div class="description">
							<h1>Entretien de vos jardins</h1>
							<p> Les éléments de votre jardin sont vivants et nécessitent des soins permanents. <br>
								Bien entretenus, votre jardin pourra prospérer et perdurer. <br>
								Nous vous proposons des contrats de maintenance et d’entretien avec des formules a	daptées à tous
									les types de jardin (sec, zen, champêtre et romantique, contemporain, aquatique, etc).</p>
						</div>
					</div>
			</section >
		
		

		<!-- IMAGE -->
		<div class="parallax-window" data-parallax="scroll" data-image-src="../assets/bg2-min.jpg">
		</div>
		
		<!-- PRODUCTS -->

			<section id="products">
				<h1 class="main-titles">PRODUITS</h1>

				<div class="cols">
					<!-- One -->
					
						<div class="col" ontouchstart="this.classList.toggle('hover');">
							<div class="container">
								<div class="front" style="background-image: url(../assets/Produits/Pepiniere-min.gif)">
									<div class="innerr">
										<p>Pépinière</p>
									</div>
								</div>
								<div class="back" style="background-image: url(../assets/Produits/Pepiniere-min.gif)">
									<div class="innerr" >
										<p>Nous offrons une ample gamme de plantes d’interieur et d’exterieur, d’arbres et d’arbustes, 
											de fleurs et de couvre sol.</p>
									</div>
								</div>
							</div>
						</div>

					<!-- two -->
					
						<div class="col" ontouchstart="this.classList.toggle('hover');">
							<div class="container">
							<div class="front" style="background-image: url(../assets/Produits/arrosage-min.gif)">
									<div class="innerr">
									<p>Arrosage automatique</p>
									</div>
							</div>
							<div class="back" style="background-image: url(../assets/Produits/arrosage-min.gif)">
									<div class="innerr">
									<p>Nos systèmes d’arrosage automatique vous offrent la possibilité de gagner du temps,
										 d’économiser de l’eau et d’assurer la qualité et la longevité de vos plantes.</p>
									</div>
							</div>
							</div>
						</div>
						<!-- five -->
					
						<div class="col" ontouchstart="this.classList.toggle('hover');">
							<div class="container">
							<div class="front" style="background-image: url(../assets/Produits/eau-min.gif)">
									<div class="innerr">
									<p>Les points d’eau</p>
									</div>
							</div>
							<div class="back" style="background-image: url(../assets/Produits/eau-min.gif)">
									<div class="innerr">
										<p>De la simple mare d’ornement aux cascades ou ruisseaux les plus élaborés, nous réalisons le
											point d’eau de vos souhaits.</p>
									</div>
							</div>
							</div>
						</div>
					<!-- six -->
					
						<div class="col" ontouchstart="this.classList.toggle('hover');">
							<div class="container">
							<div class="front" style="background-image: url(../assets/Produits/mobilier-min.jpg)">
									<div class="innerr">
									<p>Mobilier de jardin</p>
									</div>
							</div>
							<div class="back" style="background-image: url(../assets/Produits/mobilier-min.jpg)">
									<div class="innerr">
										<p>Le travail ne s’arrête pas à l’achèvement des plantations de votre nouvel espace vert. Nous vous
										 accompagnons et vous proposons en plus de vous aider à le meubler.</p>
									</div>
							</div>
							</div>
						</div>
					<!-- three -->
					
						<div class="col" ontouchstart="this.classList.toggle('hover');">
							<div class="container">
							<div class="front" style="background-image: url(../assets/Produits/eclairage-min.jpg)">
									<div class="innerr">
									<p>Éclairage</p>
									</div>
							</div>
							<div class="back"  style="background-image: url(../assets/Produits/eclairage-min.jpg)">
									<div class="innerr">
										<p>Nos proposons des solutions d’eclairage creatives et agreables pour pouvoir profiter de votre 
										jardin pendant les douces nuits d’été.</p>
									</div>
							</div>
							</div>
						</div>
					<!-- four -->
					
						<div class="col" ontouchstart="this.classList.toggle('hover');">
							<div class="container">
							<div class="front" style="background-image: url(../assets/Produits/paysagiste-min.gif)">
									<div class="innerr">
									<p>Maçonnerie paysagère</p>
									</div>
							</div>
							<div class="back" style="background-image: url(../assets/Produits/paysagiste-min.gif)">
									<div class="innerr">
										<p>Nous vous offrons également de réaliser vos terrasses, vos patios ou vos allées de jardin.</p>
									</div>
							</div>
							</div>
						</div>
					
				</div>
									
			</section>
	</main>	

<!-- Footer -->
	<footer id="footer">
		<div class="inner">
			<h2 class="major">Pour une consultaion ou un devis</h2>
			<!-- <h4>Pour une consultaion ou un devis</h4> -->
			
			<ul class="contact">
				<!-- <li >
					<i style="margin-left: -30px; margin-right:15px" class="fas fa-map-marked"></i>
					Untitled Inc<br />
					1234 Somewhere Road Suite #2894<br />
					Nashville, TN 00000-0000
				</li> -->
				<li ><i style="margin-left: -30px; margin-right:15px"  class="fas fa-phone"></i>(+212) 6 15 11 89 33</li>
				<!-- <li > <i style="margin-left: -30px; margin-right:15px"  class="fas fa-at"></i> information@untitled.tld</li> -->
				<!-- <li class="icon brands fa-twitter"><a href="#">twitter.com/untitled-tld</a></li>
				<li class="icon brands fa-facebook-f"><a href="#">facebook.com/untitled-tld</a></li>
				<li class="icon brands fa-instagram"><a href="#">instagram.com/untitled-tld</a></li> -->
			</ul>
			
			<form >
				<div class="fields">
					<div class="field">
						<label for="name">Nom</label>
						<input type="text" name="name" id="name" [(ngModel)]="mail.name" />
					</div>
					<div class="field">
						<label for="email">Email</label>
						<input type="email" name="email" id="email" [(ngModel)]="mail.email" />
					</div>
					<div class="field">
						<label for="message">Message</label>
						<textarea name="message" id="message" rows="4" [(ngModel)]="mail.message" ></textarea>
					</div>
				</div>
				<div class="actions" style="display: flex;">
					<input (click)="SubmitEmail()" class="button" type="submit" value="Envoyer" />
					<div class="SubmitStatu">
						<div *ngIf="SubmitStatu == 'await'">
							<img width="35" height='35' src="../assets/Footer/await.jpg" alt="">
						</div>
						<div *ngIf="SubmitStatu == 'success'">
							<img width="15" height='15' src="../assets/Footer/success.png" alt="">
							<small>MERCI pour votre email ! Nous vous contacterons bientôt.</small>
						</div>
						<div *ngIf="SubmitStatu == 'failed'">
							<img width="15" height='15' src="../assets/Footer/failed.jpg" alt="">
							<small style="color:red;">Echec, veuillez réessayer.</small>
						</div>
					</div>
				</div>
			</form>
			
		</div>
		<div class="copyright">
			<span class="textCopy">
					BioPaysage 2020 &reg;  Designed by 
					<a href="https://www.linkedin.com/in/mouad-chaouki-133937186/" style="font-size: large; font-weight : bold; color:rgb(23, 122, 183);"> <i class="fab fa-linkedin"></i></a>
			</span>
		</div>

	</footer>

<!--SCRIPT-->

